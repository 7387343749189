color = {
    1: #ffb3ba;
    2: #ffdfba;
    3: #ffffba;
    4: #baffc9;
    5: #bae1ff;
}

.dashboard-page{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}

.dashboard-header{
    background-color: #ffb3ba;
    flex: 1;
    width: 100%;
}

.dashboard-content{
    background-color: #ffdfba;
    flex: 9;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard-chart{
    background-color: #baffc9;
    flex: 5;
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
}

.dashboard-table{
    background-color: #baffc9;
    flex: 5;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.chart-container{
    border: 1;
    border-color: #f00000;
    height: 30%;
}

.dahsboard-container{
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    align-items: center;
    background-color: #bdc3c7;
}

.home-page-all-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    align-items: center;
    background-color: #bdc3c7;
}

.home-container{
    flex: 1;
    background-color: #fffff0;
}

.chart-container{
    flex: 5;
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.home-page-leaderBoard-icon{
    cursor: pointer;
    align-items: flex-center;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #000000;
    padding: 10;
}

.home-page-leaderBoard-icon:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.home-page-leaderBoard-icon-container{
    display: flex;
    flex-direction: column;
}

@media (max-width: 800px){
    .home-page-title{
        font-size: 40px;
    }

    .home-page-logo-container{
        height: 8%;
    }

    .home-page-logo-banner{
        height: 80%;
    }

    .home-page-organizer{
        font-size: 18px;
        line-height: 25px;
    }
}

@media (max-width: 500px){
    .home-page-title{
        font-size: 25px;
    }

    .home-page-organizer{
        font-size: 12px;
        line-height: 18px;
    }

    .home-page-pilih-pemain{
        font-size: 18px;
    }

    .home-page-leaderBoard-icon{
        max-width: 60px;
    }

    .home-page-logo-banner{
        height: 70%;
    }
}